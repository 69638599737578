/* ======================================================

    Footer

====================================================== */
.gl-footer{
    position: relative;
    background: linear-gradient(to right, rgba(0,120,215,1) 0%,rgba(12,45,128,1) 100%);
    background-size: 100% 100%;
    background-position: 50% 0;
    background-repeat: no-repeat;

    &:before{
        position: absolute;
        display: block;
        content: '';
        top: -33px;
        left: 50%;
        transform: translateX(-50%);
        width: 166px;
        height: 33px;
        background: url(../images/ft_upper.png) no-repeat 0 0 / 100% auto;
    }
    & a{
        color: inherit;
        transition: opacity 300ms;
    }
    & a:hover{
        color: inherit;
        opacity: 0.7;
    }
    &__inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 28px;
        color: #ffffff;
    }
    &__info{
        font-size: 1.2rem;
        width: 430px;
    }
    &__logo{
        width: 278px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 1499px) {
        background-size: 1500px 100%;
    }
    @media screen and (max-width: 767px) {
        &__inner{
            padding-bottom: 10px;
        }
        &__info{
            width: 100%;
            text-align: center;
            margin-bottom: 40px;
        }
        &__logo{
            width: 278px;
            margin: 0 auto 20px;
        }
    }
}

/* ======================================================

    ナビゲーション

====================================================== */

.gl-footer-nav{
    
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% - 430px );
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: bold;

    & > ul{
        width: 25%;
        max-width: 170px;
    }
    &__sub{
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: normal;
    }
    & li{
        margin-bottom: 1em;
    }
    & li ul{
        margin-top: 1em;
        margin-bottom: 1em;
    }
    & li:last-child{
        margin-bottom: 0;
    }
    &__sub li{
        margin-bottom: 0.5em;
    }

    @media screen and (max-width: 900px) {
        & > ul{
            width: 33.33%;
            max-width: none;
            text-align: center;
        }
        & > ul:last-child{
            width: 100%;
            padding-top: 40px;
            display: flex;
        }
        & > ul:last-child li{
            width: 50%;
            text-align: left;
            padding-left: 10px;
        }
        & > ul:last-child li:first-child{
            text-align: right;
            padding-right: 10px;
            border-right: 1px solid #ffffff;
        }
    }

    @media screen and (max-width: 767px) {

        width: 100%;
        font-size: 1.4rem;
        
        &__sub{
            font-size: 1.2rem;
        }
    }
}

/* ======================================================

    copyright

====================================================== */
.gl-leagal{

    background-color: #ffffff;
    
    &__container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 21px;
        padding-bottom: 18px;
    }
    &__unitika{
        line-height: 1;
    }
    &__unitika img{
        width: 150px;
    }
    &__copyright{
        font-size: 1.2rem;
        line-height: 1.5;
    }
    @media screen and (max-width: 767px) {
        &__container{
            display: block;
        }
        &__unitika{
            text-align: center;
            margin-bottom: 15px;
        }
        &__unitika img{
            width: auto;
            height: 24px;
        }
        &__copyright{
            text-align: center;
            font-size: 1.0rem;
        }
    }
}