/* ======================================================

    Header

====================================================== */
.gl-header{

    position: relative;
    width: 100%;
    background-color: #ffffff;
    z-index: 1080;

    &__upper{
        height: auto;
    }

    &__logo{
        display: block;
        width: 100%;
        padding: 7px 0 30px;
        max-width: 350px;
        margin: 0 auto;
        text-align: center;
    }
    &__copy{
        font-size: 0.9rem;
        line-height: 35px;
    }
    &__menu{
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        width: 65px;
        height: 65px;
        text-indent: -100%;
        background: url(../images/nav.png) no-repeat center top/65px 130px;
        overflow: hidden;
        cursor: pointer;
    }
    &__menu.is-open{
        background-position: 0 -65px;
    }
    &__inquiry{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
        height: 110px;
        top: 0;
        right: 0;
        text-align: center;
        font-size: 1.6rem;
        color: #ffffff;
        background: linear-gradient(to bottom, #0c2d80 6%,#0078d7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    &__inquiry .icon-mail{
        margin-top: -10px;
    }
    &__inquiry .icon-mail:before{
        display: block;
        font-size: 3.0rem;
        color: #ffffff;
    }
    @media screen and (max-width: 1199px) {
        &__inquiry{
            width: 110px;
            height: 110px;
            font-size: 1.2rem;
        }
    }
    @media screen and (max-width: 767px) {
        
        position: fixed;
        top: 0;

        &__logo{
            width: 200px;
            padding: 13px 0 10px;
        }
        &__copy{ display: none; }
        &__menu{ display: block; }
        &__inquiry{ display: none; }
    }
}
/* ======================================================

    下段

====================================================== */
.gl-header__nav{
    margin-bottom: 30px;
    font-weight: bold;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.gl-nav{
    display: flex;
    justify-content: space-between;
    list-style: none;
    max-width: 600px;
    margin: 0 auto;

    & li{
        position: relative;
    }
    & a{
        color: #000000;
        padding: 0 10px 10px 10px;
        transition: 300ms all;
        border-bottom: 2px solid #ffffff;
        white-space: nowrap;
    }
    & a:hover,
    & a.active{
        border-bottom: 2px solid #0C2D80;
        color: #0C2D80;
        opacity: 1;
        transition: 300ms all;
    }

    @media screen and (max-width: 767px) {

        display: block;

        & a,
        & a.active{
            position: relative;
            display: block;
            color: #ffffff;
            padding: 10px 10px 10px 10px;
            transition: color 300ms;
            border-bottom: 1px solid #ffffff;
        }
        & a:hover{
            border-bottom: 1px solid #ffffff;
            color: #ffffff;
        }
        & a:not(.icon-close):before{
            position: absolute;
            content: '\e901';
            color: #ffffff;
            font-weight: bold;

            top: 50%;
            transform: translateY(-50%);
            right: 5px;

            font-family: 'osk' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

/* ======================================================

    2nd

====================================================== */
.gl-nav-second{
    position: absolute;
    display: none;
    transform: translateX(-50%);
    top: calc( 100% + 4px );
    left: 50%;
    text-align: center;
    background-color: #ffffff;

    .gl-nav & a:hover{
        border-bottom: 2px solid #ffffff;
        @media screen and (max-width: 767px) {
            border-bottom: 1px solid #0C2D80;
        }
    }
    .navbar & a:before{
        color: #0C2D80;
    }

    & a{
        position: relative;
        display: block;
        color: #000000;
        padding: 10px 10px 10px 10px;
        transition: color 300ms;
        font-size: 1.4rem;
        border-bottom: 1px solid #0C2D80;
    }
    & a:hover{
        color: #000000;
        border-bottom: 1px solid #0C2D80;
    }

    @media screen and (max-width: 767px) {
        position: relative;
        display: none;
        transform: none;
        top: 0;
        left: 0;
        text-align: left;
    }
}

/* ======================================================

   c-offcanvas 

====================================================== */

.c-offcanvas{
    
    background: linear-gradient(to bottom, #0078d7 0%,#0c2d80 100%);

    & .icon-close{
        text-align: center;
        border-bottom: none;
    }
    & .icon-close:before{
        color: #ffffff;
        font-weight: bold;
        font-size: 2.0rem;
        vertical-align: -3px;
    }
    @media screen and (max-width: 767px) {
        top: 65px;
    }
}

.is-hidden {  visibility: hidden; }
