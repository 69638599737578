html{
  font-size: 62.5%;
  overflow-x: hidden;
}
body {
  /*font-family: -apple-system, BlinkMacSystemFont, "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Noto Sans Japanese", "Noto Sans CJK JP", sans-serif;*/
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Noto Sans Japanese", "Noto Sans CJK JP", sans-serif;
  font-size: 1.6rem;
  line-height: 2.125;
  letter-spacing: 0.08em;
  color: #5A5A5A;
  background: #fff;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  overflow-x: hidden;
}
ol,ul{
    list-style: none;
}
img{
    max-width: 100%;
    height: auto;
    border: 0px;
    line-height: 0px;
    vertical-align: top;
}
a{
    outline: none;
    text-decoration: none;
}
sup {
    font-size: 70%; 
    vertical-align: top;
    position: relative;
    top: -0.1em; 
}
sub {
    font-size: 70%; 
    vertical-align: bottom;
    position: relative;
    bottom: -0.1em; 
}
[data-ruby] {
    position: relative;
}
[data-ruby]::before {
    content: attr(data-ruby);
    position: absolute;
    top: -1em;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 0.1em;
}
@media screen and (max-width: 767px) {
  body {
    padding-top: 65px;
    line-height: 1.5;
  }
}
.gl-kerning-palt{
    -webkit-font-feature-settings: 'palt' 1;
    font-feature-settings: 'palt' 1;
}
.gl-kerning-pkna{
    -webkit-font-feature-settings: 'pkna' 1;
    font-feature-settings: 'pkna' 1;
}
.gl-text-justify{
  text-align: justify;
  text-justify: inter-ideograph;
}
.gl-font-serif{
    font-family: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
}
.u-font-bold{
  font-weight: bold;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.u-hidden{
  visibility: hidden;
}

/* ======================================================

    Common

====================================================== */
.bshadow0, input {
  box-shadow: inset 0 -2px #e7e7e7;
}
input:hover {
  box-shadow: inset 0 -2px #ccc;
}
input, fieldset {
  font-family: sans-serif;
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: 0;
}
input {
  color: inherit;
  line-height: 1.5;
  height: 1.5em;
  padding: .25em 0;
}
input:focus {
  outline: none;
  box-shadow: inset 0 -2px #449fdb;
}
//.clearfix:before, .clearfix:after {
//  content: " ";
//  display: table;
//}
//.clearfix:after {
//  clear: both;
//}
.hidden-true {
  display: none;
}

/* ======================================================

    アンカーリンクの位置ずれ回避
    ヘッダの高さ分ずらす

====================================================== */
@media screen and (max-width: 767px) {
  :target:before {
    content:'';
    display:block;
    height: 65px;
    margin: -65px 0 0;
  }
  //#XXX:before {
  //  content:'';
  //  display:block;
  //  height: 100px;
  //  margin-top: -100px;
  //  vertical-align: top;
  //}
}