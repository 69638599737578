/* ======================================================

    ページトップ

====================================================== */
.gl-footer__pagetop{
    position: fixed;
    width: 60px;
    height: 60px;
    right: 30px;
    bottom: 10px;
    display: none;
    z-index: 100;
    transition: opacity 300ms;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    &.on{
        display: block;
    }
    @media screen and (max-width: 767px) {
        right: 15px;
    }
}

/* ======================================================

    標準コンテンツコンテナ

====================================================== */
.gl-section{
	position: relative;
    padding: 50px 0;

    @media screen and (max-width: 767px) {
        padding: 30px 0;
    }
}
/* ======================================================

    下層ページメイン画像

====================================================== */
.gl-main2{
    position: relative;
    height: 364px;
    background-color: #ff0000;
    background: url(./images/main.jpg) no-repeat 50% 0/cover;

    &__wave{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;
        min-width: 1500px;
        font-size: 0;
    }

    @media screen and (max-width: 767px) {
        height: 200px;
        margin-bottom: 20px;
        
        &__wave{
            left: 0;
            transform: none;
            min-width: auto;
        }
    }
}
/* ======================================================

    標準コンテンツコンテナ
    <-- 1200px -->

====================================================== */
.gl-container{
    max-width: 1230px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
/* ======================================================

    <-- 1000px -->

====================================================== */
.gl-container-sm{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
/* ======================================================

    タイトル
    -------
    English

====================================================== */
.gl-page-title{
    color: #0C2D80;
    text-align: center;
    margin-bottom: 50px;

    &__main{
        display: inline-block;
        padding: 18px 25px 12px;
        margin-bottom: 0;
        border-bottom: 1px solid #0C2D80;

        font-size: 2.9rem;
        font-weight: bold;
        line-height: 1;
    }
    &__sub{
        font-family: 'Quicksand', sans-serif;
        font-size: 1.3rem;
        letter-spacing: 0;
        padding: 8px 0;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 25px;

        &__main{
            font-size: 2.4rem;
        }
        &__sub{
            font-size: 1.2rem;
        }
    }
}
/* ======================================================

    タ|
    イ|
    ト|   
    ル|

    右サイドに縦書きタイトルが付く

====================================================== */
.gl-container-ex{
    position: relative;
    margin: 0 auto;
    padding-top: 35px;

    &__title{
        position: absolute;
        top: 0;
        left: -90px;
        font-size: 2.4rem;
        font-weight: bold;
        color: #0C2D80;
        /* 縦書き */
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
    }
    &__title span{
        display: inline-block;
        border-right: 2px solid #0C2D80;
    }
    @media screen and (max-width: 1400px) {
        padding-left: 70px;
        
        &__title{
            top: 0;
            left: -15px;
            transform: none;
            margin-left: 0;
            padding-right: 0;
        }
    }
    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-top: 0;
        
        &__title{
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            margin: 0 auto 20px;
            padding: 0;
            text-align: center;
            font-size: 2.0rem;
            /* 横書き */
            -webkit-writing-mode: horizontal-tb;
            -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
        }
        &__title span{
            border-right: none;
            border-bottom: 1px solid #0C2D80;
        }
    }
}
/* ======================================================

    セクションタイトル
    --------------

====================================================== */
.gl-section-title{
    position: relative;
    margin-bottom: 45px;
    padding-bottom: 0.5em;
    text-align: center;
    color: #0C2D80;
    font-size: 3.0rem;
    font-weight: bold;
    line-height: 1;

    &:after{
        content: '';
        position: absolute;
        display: block;
        left: 50%;
        bottom: 5px;
        width: 72px;
        height: 2px;
        margin-left: -36px;
        background-color: #0C2D80;
    }
    & span{
        display: inline-block;
    }
    @media screen and (max-width: 767px) {
        font-size: 2.0rem;
    }
}
/* ======================================================

    明朝体
    ------------
    テキストテキスト

====================================================== */
.gl-page-lead{

    position: relative;
    margin-bottom: 80px;

    &__head{
        margin-bottom: 60px;
        font-size: 3.2rem;
        color: #000000;
        text-align: center;
    }
    &__head + p{
        text-align: justify;
        text-justify: inter-ideograph;
    }
    &__marker{
        padding-bottom: 0.25em;
        background:linear-gradient(transparent 65%, #FFD280 0%);
        background-repeat: no-repeat;
    }
    &__marker--space{
        padding-left: 0.5em;
    }
    @media screen and (max-width: 767px) {

        margin-bottom: 40px;

        &__head {
            margin-bottom: 40px;
            font-size: 2.0rem;
            line-height: 2;
        }
    }
}
/* ======================================================

    明朝体
    ------------
    テキストテキスト

====================================================== */
//.gl-lead-img{
//    position: relative;
//    font-size: 32px;
//    text-align: center;
//    margin-bottom: 80px;
//
//    & > span{
//        position: relative;
//        border-bottom: 12px solid #FFD280;
//        padding: 0 10px;
//     }
//    & > span span{
//        position: relative;
//        top: 10px;
//    }
//}
/* ======================================================

    リンク矢印 ____>

====================================================== */
.gl-link-arrow{
    font-size: 1.5rem;
    color: #0C2D80;

    &:after{
        content: '';
        display: inline-block;
        width: 2.5em;
        height: 0.5em;
        transform: skewX(60deg);
        border-right: 1px solid #0C2D80;
        border-bottom: 1px solid #0C2D80;
        vertical-align: 0.25em;
    }
    @media screen and (max-width: 991px) {
        font-size: 1.2rem;
    }
}